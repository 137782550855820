

@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";
@import "@/scss/_bulmaOverridden";

.hero-footer {
  padding-top: 4em;
}

// limit with and position in centre of container
.limitedwidth {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

// wrapper positions iframe wrt aspect ratio using padding then absolutely positions iframe
.video {
  position: relative;
  padding-bottom: 56.25%; //assume 16:9 video ratios
  height: 0;
  overflow: hidden;
}

.video iframe,  {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
